import './styles/index.scss';
import jQuery from 'jquery';
import 'bootstrap'
import 'bootstrap-datepicker';

(function($) {
    $(document).ready(($) => {
        function toggleIcon(e) {
            $(e.target)
                .prev('.panel-heading')
                .find(".more-less")
                .toggleClass('glyphicon-plus glyphicon-minus');
        }
        $('.collapse').on('hidden.bs.collapse', toggleIcon);
        $('.collapse').on('shown.bs.collapse', toggleIcon);


        /**
         * This function sets the name(s) of the uploaded file(s) in the input field
         */
        function getFileName() {
            $(".custom-file-input").on("change", function() {
                let fileName = $(this).val().split("\\").pop();
                if($(this).siblings(".custom-file-label").hasClass("selected")) {
                    $(this).siblings(".custom-file-label").append(", " + fileName);
                } else {
                    $(this).siblings(".custom-file-label").append(fileName).html(fileName);
                }

                $(this).siblings(".custom-file-label").addClass("selected")
            });
        }

        getFileName()

        // init plugin
        $('#startworkdate').datepicker({
            format: 'dd.mm.yyyy'
        });

        $('form').on('submit', function(e) {
            const form = $(this);

            if(form.get(0).checkValidity()) {
                e.preventDefault();
                $.ajax('./send.php', {
                    method: 'POST',
                    data: new window.FormData(this),
                    processData: false,
                    contentType: false,
                    success: () => {
                        form.replaceWith($("<div class=\"notfication col-12\">Besten dank für die Bewerbung</div>"));
                    },
                    error: () => {
                        form.append('Es gab einen Fehler. Die hochzuladende Datei ist fehlerhaft.');
                    }
                })
            }
        })
    });
})(jQuery);
(function() {
    'use strict';

    /**
     * Trigger validation api and send data to the form
     */
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                const sameEmail = form.querySelector('#email').value === form.querySelector('#email2').value;
                if ((form.checkValidity() === false) || (sameEmail === false)) {
                    event.preventDefault();
                    event.stopPropagation();

                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);

    /**
     * Custom validation method for constraint validation api
     * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#the-constraint-validation-api
     * @type {NodeListOf<Element>}
     */
    const form = document.querySelectorAll('.check-group ');
    for(let i = 0; form.length > i; i++) {
        const checkboxes = form[i].querySelectorAll('input[type=checkbox]');
        const checkboxLength = checkboxes.length;
        const firstCheckbox = checkboxLength > 0 ? checkboxes[0] : null;

        /**
         * Init and register change eventListener for checkboxes
         */
        function init() {
            if (firstCheckbox) {
                for (let i = 0; i < checkboxLength; i++) {
                    checkboxes[i].addEventListener('change', checkValidity);
                }
                checkValidity();
            }
        }

        /**
         * check if one of the checkbox in a group is checked
         * @return {boolean}
         */
        function isChecked() {
            for (let i = 0; i < checkboxLength; i++) {
                if (checkboxes[i].checked) return true;
            }
            return false;
        }

        /**
         * If the rule is ok, mark all checkboxes as valid, otherwise all checkboxes are invalid
         */
        function checkValidity() {
            const errorMessage = !isChecked() ? 'At least one checkbox must be selected.' : '';
            for (let i = 0; i < checkboxLength; i++) {
                checkboxes[i].setCustomValidity(errorMessage);
            }
        }

        init();
    }
})();

(function() {
    /**
     * Build the age select option dynamically min 18 years old max 68 year old
     */
    'use strict';
    const min18Years = (new Date()).getFullYear() - 18;
    const dob = document.getElementById("yearofbirth");
    for(let i = 0; i < 50; i++ ) {
        const option = document.createElement('option');
        option.value = min18Years-i;
        option.text = min18Years-i;
        dob.appendChild(option);
    }
}
)();
